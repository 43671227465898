.about {
  min-height: 100vh;
}

.about__section__one {
  width: 100%;
  height: 35vh;
  position: relative;
}

.about__section__one img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about__section__one .overlay {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__section__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: absolute;
  inset: 0;
}

.about__section__hero h1 {
  color: white;
  font-weight: bold;
  font-size: 50px;
}

.about__section__two {
  display: flex;
  padding: 2.5% 25%;
  justify-content: space-between;
  align-items: center;
}

.about__section__two div {
  flex: 0.5;
  font-size: 20px;
  text-align: left;
  margin: 0 15px;
}

.about__section__two__div__one p {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.about__section__two__div__two p {
  color: #0b4e78;
  font-size: 17px;
  font-weight: 500;
}
.about__section__three {
  padding: 2.5% 25%;
}
.about__section__three__div__one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.about__section__three__div__one p {
  font-weight: bold;
}

.about__section__three__div__one h1 {
  font-weight: bold;
  font-size: 30px;
}

.about__section__three__div__two {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.about__vision__card {
  background-color: #eee;
  padding: 20px;
  flex: 0.5;
  margin: 0 15px;
}
.about__vision__card > h1 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #0b4e78;
  text-align: center;
}
.about__vision__card > p {
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.about__section__four {
  width: 100vw;
  min-height: 20vh;
  position: relative;
  background: #f4f4f4;
  padding: 2.5% 25%;
  color: black;
}
.about__section__four h1 {
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  color: black;
  text-align: center;
  color: #8cc940;
  margin-bottom: 20px;
}
.about__section__four p {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  color: #0b4e78;
  text-align: center;
}

@media all and (max-width: 768px) {
  .about_section_one {
    padding: 5%;
  }
  .about_section_two {
    padding: 5%;
    justify-content: center;
    flex-direction: column;
  }
  .about_section_two_div1 {
    width: 100%;
  }
  .about_section_two_div1 > p {
    line-height: 35px;
    text-align: justify;
  }
  .about_section_two_div2 {
    width: 100%;
  }
  .about_section_four > div {
    flex-direction: column;
    padding: 5%;
    justify-content: center;
  }
  .about_vision {
    width: 100%;
    padding: 0 5%;
    margin-bottom: 30px;
    text-align: left;
  }
  .about_mission {
    width: 100%;
    padding: 0 5%;
    text-align: left;
  }
  .about_img {
    width: 100%;
    height: 400px;
  }
  .about_section_three > h1 {
    font-size: 60px;
    color: white;
    padding: 0 5%;
  }
  .about_section_three > h3 {
    font-size: 20px;
    color: lightgray;
    letter-spacing: 2px;
    text-align: start;
    padding: 0 5%;
    font-weight: 500;
  }
  .about_section_one_div {
    top: 32vh;
    padding-left: 18%;
  }

  .about__section__two {
    display: flex;
    flex-direction: column;
    padding: 5% 5%;
    justify-content: space-between;
    align-items: center;
  }
  .about__section__three {
    padding: 5% 5%;
    flex-direction: column;
  }
  .about__section__four {
    width: 100vw;
    min-height: 20vh;
    position: relative;
    background: #f4f4f4;
    padding: 5% 5%;
    color: black;
  }
  .about__section__three__div__two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }

  .about__vision__card {
    background-color: #eee;
    padding: 20px;
    flex: 1;
    margin: 15px 0;
  }
}
