.image__viewer__image {
  object-fit: cover;
  width: 350px;
  height: 250px;
  cursor: pointer;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Black background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.fullscreen-image-wrapper {
  max-width: 90%;
  max-height: 90%;
}

.fullscreen-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.close-button:hover {
  color: #ccc;
}

@media all and (max-width: 768px) {
  .image__viewer {
    width: 100%;
  }
  .image__viewer__image {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
}
