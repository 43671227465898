.home__section__one {
  min-height: 75vh;
  background: url("../../assets/IMG-20240901-WA0009b.jpg") center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5% 15%;
  color: white;
}

.home__section__one > h3 {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #8cc940;
}
.home__section__one > h1 {
  font-size: 40px;
  width: 45%;
  margin: 10px 0;
  text-align: center;
}
.home__section__one > p {
  font-size: 15px;
  color: #ccc;
  text-align: center;
  width: 40%;
}

.home__section__two {
  padding: 2.5% 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #0b4e78;
  color: white;
}
.home__section__two__start {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.home__section__two__end {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.home__section__two__end > button {
  border: 0;
  background: #8cc940;
  color: white;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}
.home__section__two__start > h3 {
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  text-align: start;
}
.home__section__two__start > h1 {
  font-size: 22px;
  font-weight: 600;
  text-align: start;
}

.home__section__three {
  padding: 2.5% 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  height: auto;
}
.home__section__three > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #8cc940;
}
.home__section__three > h3 {
  font-size: 17px;
  font-weight: 500;
}
.home__section__three__div {
  display: flex;
  margin-top: 40px;
  gap: 20px;
}
.home__section__three__div__block {
  min-height: 120px;
  min-width: 150px;
  flex-direction: column;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8cc940;
  border-radius: 10px;
}
.home__section__three__div__block > h3 {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  color: #0b4e78;
}
.home__icons {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  color: #0b4e78;
}
.home__icons2 {
  font-size: 30px;
  color: #8cc940;
  font-weight: 700;
}
.home__section__four {
  padding: 2.5% 25%;
  display: flex;
  justify-content: space-between;

  align-items: center;
}
.home__section__four__div1 {
  width: 45%;
}
.home__section__four__div1 > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #8cc940;
}
.home__section__four__div1 > h2 {
  font-size: 17.5px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 500;
  text-align: start;
}
.home__section__four__div1 > p {
  font-size: 14px;
  line-height: 25px;
  color: #555;
  margin-bottom: 20px;
}
.home__section__four__div1 > button {
  border: 0;
  background: #8cc940;
  color: white;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}
.home__section__four__div2 {
  width: 45%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: auto;
}
.home__section__four__div2 > img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
}
.home__section__four__div2 > span {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
}
.home__section__four__div2 > span > img {
  object-fit: cover;
  width: 50%;
  height: 200px;
}
.home__section__four__div2 > span > img:last-of-type {
  margin-left: 15px;
}
.home__section__five__div__block {
  min-height: 150px;
  min-width: 300px;
  gap: 30px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.home__section__five__div__block > div {
  width: 75%;
}
.home__section__five__div__block > div > h1 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #0b4e78;
}
.home__section__five__div__block > div > h2 {
  font-size: 13px;
  font-weight: 500;
}

@media all and (max-width: 768px) {
  .home__section__one {
    background-size: cover;
    min-height: 75vh;
    padding: 5% 5%;
  }

  .home__section__one > h3 {
    font-size: 15px;
    width: 100%;
  }
  .home__section__one > h1 {
    font-size: 30px;
    width: 100%;
  }
  .home__section__one > p {
    font-size: 13px;
    width: 100%;
  }
  .home__section__two {
    padding: 5% 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home__section__two__start {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .home__section__two__end {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .home__section__two__start > h3 {
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
  }
  .home__section__two__start > h1 {
    text-align: center;
  }
  .home__section__three {
    padding: 5% 5%;
    margin: 0;
  }
  .home__section__three__div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 40px;
  }
  .home__section__three__div__block {
    min-height: 100px;
    min-width: 300px;
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8cc940;
    border-radius: 10px;
  }
  .home__section__four {
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .home__section__four__div1 {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home__section__four__div2 {
    width: 100%;
  }
  .home__section__four__div2 > img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
  }
  .home__section__four__div2 > span {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 15px;
  }
  .home__section__four__div2 > span > img {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
  .home__section__four__div2 > span > img:last-of-type {
    margin-left: 0;
  }
}
