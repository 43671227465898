.ds__section__two {
  padding: 2.5% 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ds__section__two__div1 {
  width: 45%;
}
.ds__section__two__div1 > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #8cc940;
}
.ds__section__two__div1 > h2 {
  font-size: 17.5px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 500;
  text-align: start;
}
.ds__section__two__div1 > p {
  font-size: 14px;
  line-height: 25px;
  color: #555;
  margin-bottom: 20px;
}
.ds__section__two__div1 > button {
  border: 0;
  background: #8cc940;
  color: white;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}
.ds__section__two__div2 {
  width: 45%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: auto;
}
.ds__section__two__div2 > img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}

@media all and (max-width: 768px) {
  .ds__section__two {
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .ds__section__two__div1 {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .ds__section__two__div1 > h2 {
    text-align: center;
  }
  .ds__section__two__div2 {
    width: 100%;
  }
  .ds__section__two__div2 > img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
  }
}
