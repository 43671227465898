.galery {
  min-height: 100vh;
}
.galery__section__one {
  display: flex;
  flex-direction: column;
  padding: 2.5% 20%;
  justify-content: space-between;
  align-items: center;
}
.galery__section__one > h1 {
  color: #0b4e78;
  font-size: 20px;
  font-weight: 600;
}
.galery__section__one > p {
  font-size: 14px;
  line-height: 25px;
  text-align: center;
}
.galery__section__two__galery__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 50px 0;
  width: 100%;
}

@media all and (max-width: 768px) {
  .galery__section__one {
    padding: 5%;
  }
}
